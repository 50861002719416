<template>
  <v-container class="mt-5" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title primary-title>
            <span class="text-subtitle-1">Tickets calidad RH</span>
            <v-spacer></v-spacer>
            <v-btn 
              class="mr-2" 
              small dark color="green"
              @click="exportar()">
              <v-icon>mdi-microsoft-excel</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              @click="initialize"
              small
              rounded
            >
              Actualizar
            </v-btn>
          </v-card-title>

          <v-card-actions>
            <v-row justify="space-between">
              <v-col cols="12" md="4" lg="3">
                <v-text-field
                  label="Fecha inicial"
                  filled
                  hide-details
                  dense
                  type="date"
                  v-model="fecha_inicial"
                ></v-text-field>

              </v-col>

              <v-col cols="12" md="4" lg="3">
                <v-text-field
                  label="Fecha final"
                  filled
                  hide-details
                  dense
                  type="date"
                  v-model="fecha_final"
                ></v-text-field>
              </v-col>

              <v-spacer></v-spacer>
              
              <v-col cols="12" md="6" lg="4">
                <v-text-field
                  label="Buscar"
                  v-model="search"
                  id="id"
                  hide-details
                  filled
                  dense
                  append-icon="mdi-magnify"
                ></v-text-field>
              </v-col>

            </v-row>
          </v-card-actions>

          <v-card-actions>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="filterTickets"
                  sort-by="calories"
                  class="elevation-0"
                  :search="search"
                  item-key="name"
                  fixed-header
                  :height="tamanioPantalla"
                  dense
                  multi-sort
                >
                  <template v-slot:item.estatus="{ item }">
                    <v-chip small :color="getColor(item.estatus)" dark v-if="item.estatus==1"> Abierto </v-chip>
                    <v-chip small :color="getColor(item.estatus)" dark v-if="item.estatus==2"> Asignado </v-chip>
                    <v-chip small :color="getColor(item.estatus)" dark v-if="item.estatus==3"> Pendiente </v-chip>
                    <v-chip small color="grey"   dark v-if="item.estatus==4"> Revisión </v-chip>
                    <v-chip small color="green"  dark v-if="item.estatus==5"> Respondido </v-chip>
                    <v-chip small color="yellow"      v-if="item.estatus==6"> Cerrado </v-chip>
                    <v-chip small color="black"  dark v-if="item.estatus==7"> Rechazado </v-chip>
                  </template>

                  <template v-slot:item.id_unidad_negocio="{ item }">
                    <v-chip small v-if="item.id_unidad_negocio==1" color="#2EDAD5"> INBI </v-chip>
                    <v-chip small v-else color="#000D4A" dark> FAST </v-chip>
                  </template>

                  <template v-slot:item.historial="{ item }">
                    <v-btn color="primary" small rounded @click="historialTicket(item)" icon><v-icon>mdi-file-document</v-icon></v-btn>
                  </template>

                  <template v-slot:item.fecha_entrega="{ item }">
                    <v-chip 
                      v-if="item.fecha_entrega" 
                      small 
                      dark
                      :color="item.diferencia_dias < 0 && item.estatus != 6 ? 'red' : 'grey' "
                    >
                      {{ item.fecha_entrega }}
                    </v-chip>
                  </template>

                  <template v-slot:no-data>
                    <v-btn
                      color="primary"
                      @click="initialize"
                      rounded
                      small
                    >
                      Reset
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <!-- Historial del ticket -->
    <v-dialog
      v-model="dialogHistorial"
      max-width="1000px"
      >
      <v-card>
        <v-card-title>
          <span class="headline">Historial</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" v-for="(respuestas, i) in historial" :key="i">
                <div>Folio: {{ ticket.idticket }}</div>
                <div>Fecha: {{ respuestas.fecha }}</div>
                <div v-if="respuestas.estatus==1">RESPONDIDO</div>
                <div v-else>SIN RESPONDER</div><br/>
                <v-textarea rows="3" auto-grow outlined v-model="respuestas.motivo" label="Motivo de tu ticket" readonly></v-textarea>

                <!-- chip para el nombre del que lo contesto -->
                <v-chip small dense hide-details>
                  Respondió: <strong> {{ respuestas.jefe }} </strong> 
                </v-chip>

                <v-textarea 
                  class="mt-3"
                  rows="3" 
                  auto-grow
                  outlined 
                  v-model="respuestas.respuesta" 
                  label="Solución" 
                  v-if="ver==false" 
                  readonly
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogHistorial=false"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';
  var moment = require('moment');
  moment.locale(); 
  import funcionesExcel  from '@/mixins/funcionesExcel'
  import validarErrores  from '@/mixins/validarErrores'
  
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';


  export default {
    components:{
      Alerta,
      carga,
    },

    mixins: [ funcionesExcel, validarErrores ],
    
    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialogRechazo:false,
      motivoRechazo:'',

      longitud:10,
      search:'',
      respuesta:'',
      ver:false,
      dialogResponder: false,
      historial:{},
      dialogHistorial:false,
      ticket:{},

      jefes:[],
      jefe: null,

      dialog: false,
      headers: [
        { text: 'Folio',             value: 'idticket', },
        { text: 'Usuario',           value: 'nombre_completo' },
        { text: 'Escuela',           value: 'id_unidad_negocio' },
        { text: 'Sucursal',          value: 'plantel' },
        { text: 'Fecha de creación', value: 'fecha_apertura' },
        { text: 'F. entrega'       , value: 'fecha_entrega'     },
        { text: 'Resuelto en:',      value: 'fecha_asignacion' },
        { text: 'Estatus',           value: 'estatus' },
        { text: 'Area',              value: 'area' },
        { text: 'Encargado',         value: 'nombre_jefe' },
        { text: 'Auxiliar',          value: 'nombre_auxiliar' },
        { text: 'Historial',         value: 'historial', sortable: false },
      ],
      tickets: [],
      estatusFiltro:8,
      usuariosTickets:[],
      usuario: null,
      fecha_inicial: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      fecha_final: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    }),

    computed: {
      tamanioPantalla () {
        return this.$vuetify.breakpoint.height - 350
      },

      filterTickets () {
        let tickets = this.tickets

        tickets = tickets.filter( el => { return el.fecha_apertura >= this.fecha_inicial && el.fecha_apertura <=  ( this.fecha_final + ' 23:59:59' ) })

        if(tickets){
          return tickets.filter((ticket)=>{
            var est = ticket.estatus;
        
            if(this.estatusFiltro == 8){
              return true
            }else{
              if(est == this.estatusFiltro){return true}
            }
            
          })
        }
      },

    },

    async created () {
      this.cargar = true
      await this.initialize()
    },

    methods: {
      initialize () {
        this.cargar = true
        this.tickets = []
        this.$http.get('tickets.all.rh').then(response=>{
          this.longitud = response.data.length

          this.tickets = response.data
          for(const i in this.tickets){

            var msegMinuto = 1000 * 60; //Calcular minutos
            var msegHora = msegMinuto * 60; //Calcular Horas
            var msegDia = msegHora * 24; //Calcular Dias

            var tiempo = "";
            var dias = "";
            var horas = "";
            var minutos = "";

            if(this.tickets[i].estatus == 1){
              this.tickets[i].fecha_asignacion = "El ticket aun no ha sido asignado";
            } else if(this.tickets[i].estatus == 2 || this.tickets[i].estatus == 3 || this.tickets[i].estatus == 4 || this.tickets[i].estatus == 5 || this.tickets[i].estatus == 7){
              var fecha_asignacion_aux = new Date(this.tickets[i].fecha_asignado); //Calcula la fecha asignada
              var hoy = new Date(); //Calcula el dia de hoy
              hoy.setMonth(); //Obtiene mes
              hoy.setDate(); //Ontiene dia
              hoy.setHours(); //Obtiene las horas
              tiempo = new Date() - fecha_asignacion_aux;
              //calcular dias 
              dias = Math.floor(tiempo / msegDia)
              tiempo = tiempo - (dias * msegDia)
              //calcula horas
              horas = Math.floor(tiempo / msegHora)
              tiempo = tiempo - (horas * msegHora)
              //Calcula minutos
              minutos = Math.floor(tiempo /msegMinuto)
              tiempo = tiempo - (minutos * msegMinuto)
              //Muestra en la tabla
              this.tickets[i].fecha_asignacion = dias + " días, " + horas +" horas, y "+  minutos + " minutos";
            } else if(this.tickets[i].estatus == 6){
              var fecha_asignacion_aux = new Date(this.tickets[i].fecha_asignado); //Calcula la fecha asignada
              var hoy = new Date(); //Calcula el dia de hoy
              hoy.setMonth(); //Obtiene mes
              hoy.setDate(); //Ontiene dia
              hoy.setHours(); //Obtiene las horas
              tiempo = new Date(this.tickets[i].fecha_cierre) - fecha_asignacion_aux;
              //calcular dias 
              dias = Math.floor(tiempo / msegDia)
              tiempo = tiempo - (dias * msegDia)
              //calcula horas
              horas = Math.floor(tiempo / msegHora)
              tiempo = tiempo - (horas * msegHora)
              //Calcula minutos
              minutos = Math.floor(tiempo /msegMinuto)
              tiempo = tiempo - (minutos * msegMinuto)
              //Muestra en la tabla
              this.tickets[i].fecha_asignacion = dias + " días, " + horas +" horas, y "+  minutos + " minutos";
            }
          }

          this.getUsuarios(this.tickets)
          this.cargar = false

        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getColor (estatus) {
        if (estatus == 3) return 'red'
        else if (estatus == 1) return 'orange'
        else if (estatus == 2) return 'blue'
      },

      historialTicket(value){
        this.ticket = value
        this.historial = []
        this.$http.get('historial_tickets.estatus/'+this.ticket.idticket).then(response=>{
          for(const i in response.data.HistorialTicket){
            response.data.HistorialTicket[i].fecha = moment(response.data.HistorialTicket[i].fecha).format('MMMM Do YYYY, h:mm:ss a');
            response.data.HistorialTicket[i].fecharespuesta = moment(response.data.HistorialTicket[i].fecharespuesta).format('MMMM Do YYYY, h:mm:ss a');
            //esta es la fecha y hora en la que se comenzo el registro de respuestas
            if(response.data.HistorialTicket[i].fecharespuesta == 'marzo 4º 2024, 3:31:27 pm'){
              response.data.HistorialTicket[i].fecharespuesta = ''
            }
            this.historial.push(response.data.HistorialTicket[i])
          }
          this.dialogHistorial = true
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
        this.ver = false
      },

      exportar(){
        this.exportExcel( this.filterTickets, 'tickets_list')
      },

      getUsuarios (value) {
        this.$http.get('usuarios.all.erp').then(response=>{
          this.usuariosTickets = response.data
        }).catch(error=>{console.log(error)})
      },
    },
  }
</script>
<style>
  .v-data-table__wrapper{

  }

  .v-data-table__wrapper::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  .v-data-table--fixed-header > .v-data-table__wrapper::-webkit-scrollbar:vertical {
    width: 10px !important;
  }

  .v-data-table__wrapper::-webkit-scrollbar:vertical{
    width: 10px !important;
  }

  .v-data-table__wrapper::-webkit-scrollbar-button:increment,.v-data-table__wrapper::-webkit-scrollbar-button{
    display: none;
  }

  .v-data-table__wrapper::-webkit-scrollbar:horizontal{
    height: 10px !important;
  }

  .v-data-table__wrapper::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }


</style>