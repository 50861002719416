var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-5",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"shadowCard"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('span',{staticClass:"text-subtitle-1"},[_vm._v("Tickets calidad RH")]),_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","dark":"","color":"green"},on:{"click":function($event){return _vm.exportar()}}},[_c('v-icon',[_vm._v("mdi-microsoft-excel")])],1),_c('v-btn',{attrs:{"color":"primary","small":"","rounded":""},on:{"click":_vm.initialize}},[_vm._v(" Actualizar ")])],1),_c('v-card-actions',[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","md":"4","lg":"3"}},[_c('v-text-field',{attrs:{"label":"Fecha inicial","filled":"","hide-details":"","dense":"","type":"date"},model:{value:(_vm.fecha_inicial),callback:function ($$v) {_vm.fecha_inicial=$$v},expression:"fecha_inicial"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","lg":"3"}},[_c('v-text-field',{attrs:{"label":"Fecha final","filled":"","hide-details":"","dense":"","type":"date"},model:{value:(_vm.fecha_final),callback:function ($$v) {_vm.fecha_final=$$v},expression:"fecha_final"}})],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-text-field',{attrs:{"label":"Buscar","id":"id","hide-details":"","filled":"","dense":"","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-card-actions',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.filterTickets,"sort-by":"calories","search":_vm.search,"item-key":"name","fixed-header":"","height":_vm.tamanioPantalla,"dense":"","multi-sort":""},scopedSlots:_vm._u([{key:"item.estatus",fn:function(ref){
var item = ref.item;
return [(item.estatus==1)?_c('v-chip',{attrs:{"small":"","color":_vm.getColor(item.estatus),"dark":""}},[_vm._v(" Abierto ")]):_vm._e(),(item.estatus==2)?_c('v-chip',{attrs:{"small":"","color":_vm.getColor(item.estatus),"dark":""}},[_vm._v(" Asignado ")]):_vm._e(),(item.estatus==3)?_c('v-chip',{attrs:{"small":"","color":_vm.getColor(item.estatus),"dark":""}},[_vm._v(" Pendiente ")]):_vm._e(),(item.estatus==4)?_c('v-chip',{attrs:{"small":"","color":"grey","dark":""}},[_vm._v(" Revisión ")]):_vm._e(),(item.estatus==5)?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}},[_vm._v(" Respondido ")]):_vm._e(),(item.estatus==6)?_c('v-chip',{attrs:{"small":"","color":"yellow"}},[_vm._v(" Cerrado ")]):_vm._e(),(item.estatus==7)?_c('v-chip',{attrs:{"small":"","color":"black","dark":""}},[_vm._v(" Rechazado ")]):_vm._e()]}},{key:"item.id_unidad_negocio",fn:function(ref){
var item = ref.item;
return [(item.id_unidad_negocio==1)?_c('v-chip',{attrs:{"small":"","color":"#2EDAD5"}},[_vm._v(" INBI ")]):_c('v-chip',{attrs:{"small":"","color":"#000D4A","dark":""}},[_vm._v(" FAST ")])]}},{key:"item.historial",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","small":"","rounded":"","icon":""},on:{"click":function($event){return _vm.historialTicket(item)}}},[_c('v-icon',[_vm._v("mdi-file-document")])],1)]}},{key:"item.fecha_entrega",fn:function(ref){
var item = ref.item;
return [(item.fecha_entrega)?_c('v-chip',{attrs:{"small":"","dark":"","color":item.diferencia_dias < 0 && item.estatus != 6 ? 'red' : 'grey'}},[_vm._v(" "+_vm._s(item.fecha_entrega)+" ")]):_vm._e()]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary","rounded":"","small":""},on:{"click":_vm.initialize}},[_vm._v(" Reset ")])]},proxy:true}])})],1)],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"1000px"},model:{value:(_vm.dialogHistorial),callback:function ($$v) {_vm.dialogHistorial=$$v},expression:"dialogHistorial"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Historial")])]),_c('v-card-text',[_c('v-container',[_c('v-row',_vm._l((_vm.historial),function(respuestas,i){return _c('v-col',{key:i,attrs:{"cols":"12"}},[_c('div',[_vm._v("Folio: "+_vm._s(_vm.ticket.idticket))]),_c('div',[_vm._v("Fecha: "+_vm._s(respuestas.fecha))]),(respuestas.estatus==1)?_c('div',[_vm._v("RESPONDIDO")]):_c('div',[_vm._v("SIN RESPONDER")]),_c('br'),_c('v-textarea',{attrs:{"rows":"3","auto-grow":"","outlined":"","label":"Motivo de tu ticket","readonly":""},model:{value:(respuestas.motivo),callback:function ($$v) {_vm.$set(respuestas, "motivo", $$v)},expression:"respuestas.motivo"}}),_c('v-chip',{attrs:{"small":"","dense":"","hide-details":""}},[_vm._v(" Respondió: "),_c('strong',[_vm._v(" "+_vm._s(respuestas.jefe)+" ")])]),(_vm.ver==false)?_c('v-textarea',{staticClass:"mt-3",attrs:{"rows":"3","auto-grow":"","outlined":"","label":"Solución","readonly":""},model:{value:(respuestas.respuesta),callback:function ($$v) {_vm.$set(respuestas, "respuesta", $$v)},expression:"respuestas.respuesta"}}):_vm._e()],1)}),1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialogHistorial=false}}},[_vm._v(" Cerrar ")])],1)],1)],1),(_vm.respuestaAlerta)?_c('Alerta',{attrs:{"parametros":_vm.parametros}}):_vm._e(),(_vm.cargar)?_c('carga'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }